import React from 'react'
import { graphql } from 'gatsby'

import GraphQLErrorList from '../components/graphql-error-list'
import Layout from '../containers/layout'
import Lawyer from '../components/lawyer'
import SEO from '../components/seo'

export const query = graphql`
  query LawyerTemplate($id: String!) {
    lawyer: sanityPerson(id: { eq: $id }) {
      id
      slug {
        current
      }
      name
      lastname
      position
      image {
        asset {
          _id
        }
      }
      email
      tel
      expTitle
      showOthers
      showPubs
      showReco
      _rawExp
      _rawReco
      _rawBio
      listTitle3
      listTitle2
      listTitle
      listItems3 {
        link
        title
        description
        icon {
          asset {
            _id
          }
        }
      }
      listItems2 {
        link
        title
        description
        icon {
          asset {
            _id
          }
        }
      }
      listItems {
        link
        title
        description
        icon {
          asset {
            _id
          }
        }
      }
    }
  }
`

const LawyerTemplate = props => {
  const { data, errors } = props
  const lawyer = data && data.lawyer
  return (
    <Layout>
      {errors && <SEO title="GraphQL Error" />}
      {lawyer && <SEO title={lawyer.title || 'Untitled'} />}

      {errors && <GraphQLErrorList errors={errors} />}

      {lawyer && <Lawyer {...lawyer} />}
    </Layout>
  )
}

export default LawyerTemplate
