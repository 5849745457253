import React from 'react'

import { buildImageObj } from '../lib/helpers'
import { imageUrlFor } from '../lib/image-url'

import BlockContent from './block-content'

import SEO from '../components/seo'

const SubheadingClass = 'dib relative w-100 f4 b'
const ParagraphClass = 'dib relative w-100 measure-wide dark-gray fw4 mb4'
const HeadingClass = 'dib relative w-100 measure f2 fw5 mv3 black'
const LinkClass = 'dib relative link dim inherit underline'

function Lawyer(props) {
  const {
    _rawExp,
    _rawBio,
    _rawReco,
    showOthers,
    showReco,
    showPubs,
    listTitle3,
    listItems3,
    listTitle2,
    listItems2,
    listTitle,
    listItems,
    email,
    expTitle,
    exp,
    tel,
    name,
    lastname,
    position,
    image
  } = props

  const TelAndEmail = ({ mobile }) => (
    <p className={mobile ? `dib relative w-100 ph4 f5` : ParagraphClass}>
      Tel:{' '}
      <a className={LinkClass} href={`tel:${tel}`}>
        {tel}
      </a>
      {mobile ? <br /> : ` • `}
      Email:{' '}
      <a className={LinkClass} href={`mailto:${email}`}>
        {email}
      </a>
    </p>
  )

  return (
    <>
      <SEO title={name} description={exp} />
      <article>
        <section className="dib relative w-100 bg-white lh-copy dark-gray f5" id="person">
          <div className="db center mw8 ph4 pv6">
            <div className="row">
              <div className="col-xs-12 col-md-7">
                <div className="dib relative w-100 pr0 pr4-l">
                  {tel && email ? (
                    <div className="dib dn-l fixed w-100 bottom-0 left-0 bg-white z-1 shadow-light">
                      <TelAndEmail mobile />
                    </div>
                  ) : null}
                  {image && image.asset ? (
                    <div
                      className="dib dn-l relative w-100 br3 lawyer-pic"
                      style={{
                        background: `url(${imageUrlFor(
                          buildImageObj(image)
                        ).url()}) no-repeat center center / cover`
                      }}
                    >
                      &nbsp;
                    </div>
                  ) : null}
                  <h1 className={HeadingClass}>
                    {name} {lastname}
                  </h1>
                  {position ? <p className={SubheadingClass}>{position}</p> : null}
                  {_rawBio ? <BlockContent blocks={_rawBio} /> : null}
                  {_rawExp ? (
                    <div className="dib relative w-100">
                      <strong className={`${SubheadingClass} mt4`}>
                        {expTitle || `Areas of expertise`}
                      </strong>
                      <BlockContent blocks={_rawExp} />
                    </div>
                  ) : null}
                  {listTitle ? (
                    <strong className={`${SubheadingClass} mt4 mb3`}>{listTitle}</strong>
                  ) : null}
                  {listItems && listItems.length > 0
                    ? listItems.map((object, index) => (
                        <div className="dib relative w-100 mb4" key={`edu-${index}`}>
                          <strong className="dib relative w-100 measure-wide dark-gray fw4">
                            {object.title}
                          </strong>
                          <p className="dib relative w-100 measure-wide gray fw4 f6 mv0">
                            {object.description}
                          </p>
                        </div>
                      ))
                    : null}
                </div>
              </div>
              <div className="col-xs-12 col-md-5">
                {image && image.asset ? (
                  <div
                    className="dn dib-l relative w-100 br3 lawyer-pic"
                    style={{
                      background: `url(${imageUrlFor(
                        buildImageObj(image)
                      ).url()}) no-repeat center center / cover`
                    }}
                  >
                    &nbsp;
                  </div>
                ) : null}
                <div className="dn dib-l relative w-100">
                  <TelAndEmail />
                </div>
                {showReco ? (
                  <div className="dib relative w-100">
                    <strong className={`${SubheadingClass}`}>Awards</strong>
                    <BlockContent blocks={_rawReco} />
                  </div>
                ) : null}
                {showPubs ? (
                  <div className="dib relative w-100">
                    <strong className={`${SubheadingClass} mt4 mt5-l mb3`}>
                      {listTitle2 || `Publications`}
                    </strong>
                    {listItems2.map((object, index) =>
                      object.link ? (
                        <a
                          className="dib relative w-100 mb4 link dim"
                          target="_blank"
                          href={object.link}
                          rel="noopener noreferrer"
                          key={`pub-${index}`}
                        >
                          <strong className="dib relative w-100 measure-wide dark-gray fw4">
                            <img className="dib relative w1 h1 fit v-mid mr2" alt={object.title} src="/img/link.svg" />
                            {object.title}
                          </strong>
                          <p className="dib relative w-100 measure-wide gray fw4 f6 mv0">
                            {object.description}
                          </p>
                        </a>
                      ) : (
                        <div className="dib relative w-100 mb4" key={`pub-${index}`}>
                          <strong className="dib relative w-100 measure-wide dark-gray fw4">
                            {object.title}
                          </strong>
                          <p className="dib relative w-100 measure-wide gray fw4 f6 mv0">
                            {object.description}
                          </p>
                        </div>
                      )
                    )}
                  </div>
                ) : null}
                {showOthers ? (
                  <div className="dib relative w-100">
                    {listTitle3 ? (
                      <strong className={`${SubheadingClass} mt4 mb3`}>{listTitle3}</strong>
                    ) : null}
                    {listItems3 && listItems2.length > 0
                      ? listItems3.map((object, index) =>
                          object.link ? (
                            <a
                              className="dib relative w-100 mb4 link dim"
                              target="_blank"
                              href={object.link}
                              rel="noopener noreferrer"
                              key={`other-${index}`}
                            >
                              <strong className="dib relative w-100 measure-wide dark-gray fw4">
                                <img
                                  className="dib relative w1 h1 fit v-mid mr2"
                                  alt={object.title}
                                  src="/img/link.svg"
                                />
                                {object.title}
                              </strong>
                              <p className="dib relative w-100 measure-wide gray fw4 f6 mv0">
                                {object.description}
                              </p>
                            </a>
                          ) : (
                            <div className="dib relative w-100 mb4" key={`pub-${index}`}>
                              <strong className="dib relative w-100 measure-wide dark-gray fw4">
                                {object.title}
                              </strong>
                              <p className="dib relative w-100 measure-wide gray fw4 f6 mv0">
                                {object.description}
                              </p>
                            </div>
                          )
                        )
                      : null}
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </section>
      </article>
    </>
  )
}

export default Lawyer
